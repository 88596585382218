import { TokenListURLMap } from '@/types/TokenList';

const tokenlists: TokenListURLMap = {
  Balancer: {
    Allowlisted: '',
  },
  External: [],
  Bridge: [],
};

export default tokenlists;
