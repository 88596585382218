import { Keys } from '../types';

const keys: Keys = {
  infura: '7039bd4be95f4c26ae39452d256908a2',
  alchemy: 'cQGZUiTLRCFsQS7kbRxPJK4eH4fTTu88',
  graph: '7c7b4c36244b0b86171a3931eaf9bb23',
  balancerApi: 'da2-7v6khhpxwbfm3hzoofmenop4sa',
};

export default keys;
